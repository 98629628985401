@import url("https://fonts.googleapis.com/css2?family=Play&display=swap");

.Nav{
    display: flex;
    background-color: rgb(0, 0, 0);
    min-height: 7.5vh;
    position: relative;
    z-index: 10;
    box-shadow: 2px 2px 11px 0 #01D3FE;
    /*flex-direction: column;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    /*font-size: calc(10px + 2vmin);*/
    /*color: #01D3FE;*/

}

.logoHand{
    vertical-align:middle;
    height: 1em;
}

.logoHandNav{
    height: 4vh;
}

.Left{
    /*float: left;*/
    text-align: left;
    width: 50%;
    left: 1vw;
}

.Right{
    /*float: right;*/
    right: 0;
    text-align: right;
    width: 50%;
}

.Logo{
    /*font-size: 3vh;*/
    color: #01D3FE;
    font-family: 'Play', sans-serif;
    font-weight: 400;
}

.navLogo {
    font-size: 3vh;
    width: fit-content;
    padding: .2em;
    border-radius: 4px;
}

.navLogo img{
    height: 3vh!important;
}
.navLogo:hover{
    background-color: rgba(255, 255, 255, 0.4);
    cursor: pointer;
}

.Welcome {
    font-size: 1.75vh;
    color: white;
    font-weight: 400;

}
.pfp{
    border-radius: 100%;
    margin: 0 0 0 0;
    width: 4vh;
    right: calc(1vw);
}

/*.username{*/
/*    right: calc(1vw + 6em + 4vh + 1em);*/
/*}*/

#signOut{
    right: calc(1vw + 4vh + 1em);
    color: white!important;
    width: 7em;
}

#adminButton{
    right: calc(1vw + 4vh + 1em);
    width: 5em;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
