@import url(https://fonts.googleapis.com/css2?family=Play&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* For portrait */
@media screen and (orientation: portrait) {
  .contain{
    display: block;
  }
}

/* For landscape */

@media screen and (orientation: landscape) {
  .contain{
    display: flex;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
#gfmButton{
   background-color: #02a95c;
   -webkit-filter: brightness(110%);
           filter: brightness(110%);
 }
#instaGfmButton{
  background-color: #02a95c;
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
}
#venmoButton{
  background-color: #3D95CE;
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
}
#instaVenmoButton{
  background-color: #3D95CE;
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
}

#petitionButton{
   background-color: #6600ce;
   -webkit-filter: brightness(110%);
           filter: brightness(110%);
  height: 40px;
 }

#instaPetitionButton{
  background-color: #6600ce;
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
  /*width: 500px;*/
  /*font-size: 30px;*/
}

#directButton{
  background-color: #994636;
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
}
#instaDirectButton{
  background-color: #994636;
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
}

#cashAppButton:hover, #venmoButton:hover, #gfmButton:hover, #petitionButton:hover, #directButton:hover{
    -webkit-filter: brightness(90%);
            filter: brightness(90%);
  }

#cashAppButton, #venmoButton, #gfmButton, #petitionButton, #directButton{
  height: 40px;
}




#instaCashAppButton, #instaVenmoButton, #instaGfmButton, #instaDirectButton, #instaPetitionButton{
  width: 500px;
  height: 80px;
}

#instaCashAppButton .MuiButton-label, #instaVenmoButton .MuiButton-label, #instaGfmButton .MuiButton-label, #instaDirectButton .MuiButton-label, #instaPetitionButton .MuiButton-label{
  font-size: 30px;
}
#instaCashAppButton .MuiButton-label .MuiButton-endIcon img, #instaVenmoButton .MuiButton-label .MuiButton-endIcon img, #instaGfmButton .MuiButton-label .MuiButton-endIcon img{
 width: 120px;
}

#instaPetitionButton .MuiButton-label .MuiButton-endIcon img{
  width: 25px;
}
#instaDirectButton .MuiButton-label .MuiButton-endIcon svg{
  font-size: 35px;
}


#cashAppButton{
  background-color: white;
}
#instaCashAppButton{
  background-color: white;
}

#instaButton{
  height: 40px;
}

.openStories{
  text-decoration: none;
  color: white;
  background-color: #f50057;
  border-radius: 4px;
  padding: 7px;
  box-shadow: 0px 0px 5px 2px darkred;
  font-size: 0.75em;
}
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.modal{
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vh;
/*.modal-body {*/
  max-height: calc(100vh - 10px);
  overflow-y: auto;
  border-radius: 20px;
  outline: 0!important;
/*}*/
}

#closeModal{
  cursor: pointer;
  color: red;
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 1.3em;
}
#closeModal2{
  cursor: pointer;
  color: red;
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 1.3em;
}

.donationButton{
  margin-top: 10px!important;

}
.paper{
  background-color: rgba(0, 0, 0, .85);
  font-size: 1.5em;
  text-align: center;
  border-radius: 20px;
  padding: 50px;
  color: white;
  outline: 0;

}

.progressBar{
  margin: 40px;
  width: 50%!important;
}

.carouselText{
  font-size: .75em;
  font-weight: 500;
  margin-bottom: 5px;
}

.cause{
  /*width: 1000px;*/
  transition: .5s ease-out;
  border-radius: 100%;
  /*padding: 100px;*/
  cursor: pointer;
}

.figure-img{
  border-radius: 100%;
}
.cause:hover{
  -webkit-filter: drop-shadow(0 0 50px darkblue) brightness(70%) grayscale(50%);
          filter: drop-shadow(0 0 50px darkblue) brightness(70%) grayscale(50%);
  transform: scale(1.05);
  transition: .25s ease-out;

}

.slick-dots{
  -webkit-filter: invert(100%);
          filter: invert(100%);
}

.sliderInput{
  color: white!important;
}
.MuiTypography-colorTextSecondary {
  color: white!important;
}
MuiInput-underline:before {

  border-bottom-color: rgba(255, 255, 255, 0.42)!important;

}

.center{
  margin-left: auto;
  margin-right: auto;
}



.App-header {
  background-color: #0A2640;
  min-height: 92.5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#googleSignIn{
  /*box-shadow: rgba(255, 255, 255, .25) 0 0 10px 2px;*/
  font-weight: 500;
  border-radius: 7.5px!important;
}

#googleSignIn:hover{
  box-shadow: rgba(255, 255, 255, .25) 0 0 10px 5px!important;
  font-weight: 500;
  border-radius: 7.5px!important;
}
#googleSignIn div{
  border-radius: 7.5px!important;
}

@media only screen and (max-width: 400px) {
  .variableContainer{
    width: 75%;
  }
  .causeContainer{
    width: 90%;
  }
}

@media only screen and (max-width: 600px) and (min-width: 401px) {
  .variableContainer{
    width: 75%;
  }
  .causeContainer{
    width: 85%;
  }
}

@media only screen and (max-width: 800px) and (min-width: 601px) {
  .variableContainer{
    width: 70%;
  }
  .causeContainer{
    width: 80%;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 801px){
  .variableContainer{
    width: 60%;
  }
  .causeContainer{
    width: 70%;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 1001px) {
  .variableContainer{
    width: 50%;
  }
  .causeContainer{
    width: 60%;
  }
}
@media only screen and (min-width: 1201px) and (max-width: 1400px) {
  .variableContainer{
    width: 40%;
  }
  .causeContainer{
    width: 50%;
  }
}

@media only screen and (min-width: 1401px) {
  .variableContainer{
    width: 30%;
  }
  .causeContainer{
    width: 40%;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Toaster__alert{
  background-color: #f50057!important;
}

.Toaster__alert_text{
  color: white!important;
}

#instagramDescription{
  font-size: 2.5vh;
}
#instagramCanvasContainer{
  height: 1920px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100000;
}
#instagramCanvas{
  height: 100%;
  width: 1080px;
  background-color: #0A2640;
}

#instagramCanvas h1{
  font-size: 150px;
  padding: 0;
  margin: 0;
  color: white;
}
#instagramCanvas p{
  font-size: 75px;
  color: white;
}
#instagramCanvas h3{
  font-size: 75px;
  color: white;
}

#instagramCanvasImg{
  width: 750px;
  height: 750px;
  margin-top: 50px;
  margin-bottom: 50px;
}


.instaLogo{
  /*font-size: 3vh;*/
  color: #01D3FE;
  font-family: 'Play', sans-serif;
  font-weight: 400;
  font-size: 50px;
  position: absolute;

  right: 10px;
  bottom: 10px;
}



#instaLogoHand{
  vertical-align:middle!important;
  height: 1em!important;
}

#username{
    color:white;
}

#username-label{
    color: white;

}

.MuiInput-underline:before{
    border-bottom: 1px solid rgb(255, 255, 255) !important;
}

.MuiFormControl-root:hover .MuiInput-underline:before{
    border-bottom: 2px solid rgb(255, 255, 255) !important;

}

.container{
    border-radius: 30px;
    border-style: double;
    padding: 30px;
    border-width: thick;
    border-color: aquamarine;
    margin-top: 10px;
    margin-bottom: 10px;
}



.cropImage{
    object-fit: cover;
    border-radius: 100%;
}

.uploadButton{
    display: none;
}
p{
    font-size: .8em;
    margin-bottom: 0;
    margin-top: 0;
}

.hoverOverlayContainer {
    position: relative;
    width: 100%;
}
.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(50% - 75px);
    right: 0;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    opacity: 0;
    transition: .3s ease;
    background-color: rgba(0, 0, 0, 0.45);
}

/* When you mouse over the container, fade in the overlay icon*/
.overlay:hover {
    opacity: 1;
    cursor: pointer;
}

/* The icon inside the overlay is positioned in the middle vertically and horizontally */
.icon {
    color: white;
    position: absolute;
    top: calc(50% + 7.5px);
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}
/*.MuiInput-underline:before{*/
/*    border-bottom: 1px solid rgb(255, 255, 255) !important;*/
/*}*/

/*.MuiFormControl-root:hover .MuiInput-underline:before{*/
/*    border-bottom: 2px solid rgb(255, 255, 255) !important;*/

/*}*/

/*.MuiInputLabel-root{*/
/*    color: white!important;*/

/*}*/

/*.MuiInputBase-root{*/
/*    color: white!important;*/

/*}*/

/*.MuiOutlinedInput-notchedOutline {*/

/*    border-color: rgb(255, 255, 255) !important;*/
/*}*/

.paymentInfo{
    vertical-align: middle;
    /*horiz-align: right;*/
    /*float: right;*/

    width: 45%;
    margin-bottom: 20px;
}

.donationInfo{
    text-align: left;
    display: block;
}

.Nav{
    display: flex;
    background-color: rgb(0, 0, 0);
    min-height: 7.5vh;
    position: relative;
    z-index: 10;
    box-shadow: 2px 2px 11px 0 #01D3FE;
    /*flex-direction: column;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    /*font-size: calc(10px + 2vmin);*/
    /*color: #01D3FE;*/

}

.logoHand{
    vertical-align:middle;
    height: 1em;
}

.logoHandNav{
    height: 4vh;
}

.Left{
    /*float: left;*/
    text-align: left;
    width: 50%;
    left: 1vw;
}

.Right{
    /*float: right;*/
    right: 0;
    text-align: right;
    width: 50%;
}

.Logo{
    /*font-size: 3vh;*/
    color: #01D3FE;
    font-family: 'Play', sans-serif;
    font-weight: 400;
}

.navLogo {
    font-size: 3vh;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: .2em;
    border-radius: 4px;
}

.navLogo img{
    height: 3vh!important;
}
.navLogo:hover{
    background-color: rgba(255, 255, 255, 0.4);
    cursor: pointer;
}

.Welcome {
    font-size: 1.75vh;
    color: white;
    font-weight: 400;

}
.pfp{
    border-radius: 100%;
    margin: 0 0 0 0;
    width: 4vh;
    right: calc(1vw);
}

/*.username{*/
/*    right: calc(1vw + 6em + 4vh + 1em);*/
/*}*/

#signOut{
    right: calc(1vw + 4vh + 1em);
    color: white!important;
    width: 7em;
}

#adminButton{
    right: calc(1vw + 4vh + 1em);
    width: 5em;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

