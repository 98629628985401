.container{
    border-radius: 30px;
    border-style: double;
    padding: 30px;
    border-width: thick;
    border-color: aquamarine;
    margin-top: 10px;
    margin-bottom: 10px;
}



.cropImage{
    object-fit: cover;
    border-radius: 100%;
}

.uploadButton{
    display: none;
}
p{
    font-size: .8em;
    margin-bottom: 0;
    margin-top: 0;
}

.hoverOverlayContainer {
    position: relative;
    width: 100%;
}
.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(50% - 75px);
    right: 0;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    opacity: 0;
    transition: .3s ease;
    background-color: rgba(0, 0, 0, 0.45);
}

/* When you mouse over the container, fade in the overlay icon*/
.overlay:hover {
    opacity: 1;
    cursor: pointer;
}

/* The icon inside the overlay is positioned in the middle vertically and horizontally */
.icon {
    color: white;
    position: absolute;
    top: calc(50% + 7.5px);
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}
/*.MuiInput-underline:before{*/
/*    border-bottom: 1px solid rgb(255, 255, 255) !important;*/
/*}*/

/*.MuiFormControl-root:hover .MuiInput-underline:before{*/
/*    border-bottom: 2px solid rgb(255, 255, 255) !important;*/

/*}*/

/*.MuiInputLabel-root{*/
/*    color: white!important;*/

/*}*/

/*.MuiInputBase-root{*/
/*    color: white!important;*/

/*}*/

/*.MuiOutlinedInput-notchedOutline {*/

/*    border-color: rgb(255, 255, 255) !important;*/
/*}*/

.paymentInfo{
    vertical-align: middle;
    /*horiz-align: right;*/
    /*float: right;*/

    width: 45%;
    margin-bottom: 20px;
}

.donationInfo{
    text-align: left;
    display: block;
}
