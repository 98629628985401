#username{
    color:white;
}

#username-label{
    color: white;

}

.MuiInput-underline:before{
    border-bottom: 1px solid rgb(255, 255, 255) !important;
}

.MuiFormControl-root:hover .MuiInput-underline:before{
    border-bottom: 2px solid rgb(255, 255, 255) !important;

}
